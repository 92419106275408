<template>
  <Layout>
    <PageHeader :title="PageHeaderT" :items="PageHeaderItems" />
    <div class="row flex-1 block_content">
      <div class="card mb-0" id="orderList" style="height: 100%">
        <div
          class="
            card-header
            border border-dashed border-top-0 border-start-0 border-end-0
          "
        >
          <div class="d-flex align-items-center">
            <h5 class="card-title mb-0 flex-grow-1 py-2">
              {{ $t("permission.name") }}
            </h5>
          </div>
        </div>
        <div class="card-body d-flex bg-soft-primary overflow-hidden">
          <!-- 左侧菜单栏 -->
          <div class="body-left card mb-0 overflow-auto">
            <h5
              class="py-4 ps-4 mb-0 border-bottom"
              style="background: rgb(226, 232, 237)"
            >
              {{ $t("permission.role") }}
            </h5>
            <div class="flex-1 border-bottom">
              <LeftMenu
                v-if="roles.length"
                :treeList="roles"
                :selected="selected"
                @selectedItem="selectedItem"
              ></LeftMenu>
              <Empty flag="1" v-else></Empty>
            </div>
          </div>
          <!-- 右侧权限 -->
          <div class="body-right card flex-1 ms-5 mb-0 d-flex overflow-auto">
            <div
              class="
                d-flex
                border-bottom
                align-items-center
                justify-content-between
              "
            >
              <h5 class="py-4 ps-4 mb-0">
                {{ $t("permission.permission") }}
              </h5>
              <h6 class="me-5 mb-0 permission" v-if="currentChooseName">
                {{ currentChooseName }}
              </h6>
            </div>
            <!-- 选项 -->
            <div class="flex-1 mx-5 border-bottom" v-if="roles.length">
              <div
                v-for="(purview, i) in permissions"
                :key="i"
                :class="['mb-4', i == 0 && 'mt-4']"
              >
                <div
                  class="d-flex align-items-center mb-3"
                  @click="handlerAll(i)"
                >
                  <input
                    :disabled="!editPermission()"
                    type="checkbox"
                    class="me-2 cursor"
                    :checked="purview.checked"
                  />
                  <h5 class="mb-0">{{ purview.name }}</h5>
                </div>
                <div>
                  <div v-if="purview.permissions.length">
                    <div
                      v-for="(list, index) in purview.permissions"
                      :key="index"
                      class="mb-3 ms-4 d-flex align-items-center"
                      @click="handlerChecked(i, index)"
                    >
                      <input
                        :disabled="!editPermission()"
                        type="checkbox"
                        class="me-2"
                        :checked="list.checked"
                      />
                      {{ list.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Empty flag="1" v-else></Empty>
            <!-- 按钮 -->
            <div
              class="d-flex justify-content-center my-4"
              v-if="roles.length && editPermission()"
            >
              <button
                v-permit="'permission.role-permission'"
                type="button"
                class="btn btn-info me-4"
                @click="resetClick()"
              >
                {{ $t("base.reset") }}
              </button>
              <button
                v-permit="'permission.role-permission'"
                type="button"
                class="btn btn-primary"
                @click="saveClick()"
              >
                {{ $t("base.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <message-alert
    :alertInfo="alertInfo"
    v-if="formValidataAlert"
  ></message-alert>
</template>

<script>
import { roleTreeList, getPermission, setRolePermission } from "@/service";
import Empty from "@/components/common/Empty";
import _ from "lodash";
import LeftMenu from "./LeftMenu.vue";

export default {
  components: {
    Empty,
    LeftMenu,
  },
  data() {
    return {
      PageHeaderT: this.$t("permission.title"),
      PageHeaderItems: [
        {
          text: this.$t("permission.title"),
          active: true,
          href: "/permission",
        },
      ],
      formValidataAlert: false,
      alertInfo: {
        variant: "success",
        content: "",
        icon: "ri-error-warning-line",
      },
      roles: [],
      permissions: [],
      available_permissions: [],
      currentRole: null,
      // currentChooseIndex: 0,
      currentChooseName: "",
      selected: "",
      selectItem: null,
    };
  },
  created() {
    this.requestRole();
    // this.requestPermission();
  },
  methods: {
    selectedItem(item) {
      if (item) {
        this.currentChooseName = item.name;
        // this.roles.forEach((role, index) => {
        //   if (role.id == item.id) {
        //     this.currentChooseIndex = index;
        //   }
        // });
      }
      this.currentRole = _.cloneDeep(item);
      this.permissions = _.cloneDeep(item.permissions);
      this.permissions.forEach(function (titem) {
        const bol = _.every(titem.permissions, ["checked", true]);
        titem.checked = bol;
      });
      //   console.log(this.permissions);
    },
    resetClick() {
      this.permissions.forEach((list) => {
        list.checked = false;
        if (list.permissions) {
          list.permissions.forEach((child) => {
            child.checked = false;
          });
        }
      });
    },
    saveClick() {
      let permission = [];
      this.permissions.forEach((item) => {
        if (item.checked) {
          permission.push(item.slug);
        }
        item.permissions.forEach((subitem) => {
          if (subitem.checked) {
            permission.push(subitem.slug);
          }
        });
      });
      const params = {
        role_id: this.currentRole.id,
        permissions: permission,
      };
      setRolePermission(params)
        .then(() => {
          this.requestRole(params.role_id);
          let info = {
            variant: "success",
            content: this.$t("base.success"),
            icon: "ri-error-warning-line",
          };
          this.openAlert(info);
        })
        .catch((error) => {
          let info = {
            variant: "danger",
            content: error.message,
            icon: "ri-error-warning-line",
          };
          this.openAlert(info);
        });
    },
    /* 消息提示框 */
    openAlert(info) {
      if (info) {
        this.alertInfo = info;
      }
      this.formValidataAlert = true;
      setTimeout(() => {
        this.formValidataAlert = false;
      }, 2000);
    },
    handlerAll(index) {
      if (!this.editPermission()) {
        return;
      }
      this.permissions[index].checked = !this.permissions[index].checked;
      this.permissions[index].permissions.forEach((list) => {
        list.checked = this.permissions[index].checked;
      });
    },
    handlerChecked(index, childrenIndex) {
      if (!this.editPermission()) {
        return;
      }
      this.permissions[index].permissions.forEach((list, i) => {
        if (childrenIndex == i) {
          list.checked = !list.checked;
        }
      });
      /* 如果permissions的状态全部为true,全选状态为true */
      this.permissions.forEach((list) => {
        if (list.permissions) {
          list.checked = list.permissions.every((child) => {
            return child.checked;
          });
        }
      });
    },
    requestRole(id) {
      const params = {
        with_available_options: 1,
      };
      roleTreeList(params)
        .then((res) => {
          if (res && res.payload && res.payload.data) {
            this.roles = res.payload.data;
            this.selected = id || (this.roles.length && this.roles[0].id);
            this.roles.forEach((role) => {
              if (id == role.id) {
                this.selectItem = role;
              }
              role.num = 1;
              if (role.childs && role.childs.length) {
                role.childs = this.addSearchKey(role.childs, role.num, id);
              }
            });
            console.log(id);
            if (this.roles.length) {
              if (!this.selectItem) {
                this.selectItem = this.roles[0];
              }
              this.selectedItem(this.selectItem);
            }
          }
          if (res && res.payload && res.payload.available_permissions) {
            this.available_permissions = res.payload.available_permissions;
          }
        })
        .catch((error) => {
          console.log(error);
          let info = {
            variant: "danger",
            content: error.message,
            icon: "ri-error-warning-line",
          };
          this.openAlert(info);
        });
    },
    /* 筛选角色列表 */
    addSearchKey(childs, num, id) {
      let children = _.cloneDeep(childs);
      children.forEach((child) => {
        child.num = num + 1;
        if (id == child.id) {
          this.selectItem = child;
        }
        if (child.childs && child.childs.length) {
          child.childs = this.addSearchKey(child.childs, child.num, id);
        }
      });
      return children;
    },
    requestPermission() {
      getPermission({})
        .then((res) => {
          if (res && res.payload && res.payload.data) {
            this.permissions = res.payload.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editPermission() {
      let storeData =
        this.$store.state.login.menuList ||
        sessionStorage.getItem("set_menu_list");
      let tpermissions = null;
      if (storeData) {
        tpermissions = JSON.parse(storeData).permissions;
        if (
          tpermissions &&
          _.includes(tpermissions, "permission.role-permission")
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.body-left {
  width: 25rem;
}
.point {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border: 2px solid #000;
  border-radius: 100px;
}
.permission {
  background: rgba(10, 179, 156, 0.4);
  color: #0ab39c;
  padding: 5px 10px;
  border-radius: 4px;
  min-width: 100px;
  text-align: center;
}
</style>
